import { Breakpoint, breakpointsRaw } from '@hultafors/shared/constants';

export const minWidth = Object.entries(breakpointsRaw).reduce(
  (acc, [key, value]) => {
    return Object.assign(acc, {
      [key]: (strings: TemplateStringsArray, ...values: string[]) => {
        const size = strings
          .map((string, index) => string + (values[index] ?? ''))
          .join('');
        return `(min-width: ${value}px) ${size};
        `;
      },
    });
  },
  {},
) as Record<
  Breakpoint,
  (strings: TemplateStringsArray, ...values: string[]) => string
>;
