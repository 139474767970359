import Image from 'next/image';

import { minWidth } from '@hultafors/shared/style';

import { ImageFragment } from '@hultafors/eripiowear/types';

import {
  Inner,
  ProductPlugImage,
  ProductPlugStyled,
  ProductPlugTitle,
  TextWrapper,
} from './teaser-simple.styled';

export interface TeaserSimpleProps {
  title?: string;
  image?: ImageFragment | null;
  color?: string;
  url?: string;
}

export const TeaserSimple: React.FC<TeaserSimpleProps> = ({
  url = '',
  image,
  title = '',
}) => {
  const sizes = [
    '100vw',
    minWidth.largeMobile`50vw`,
    minWidth.desktopMedium`25vw`,
    minWidth.desktopLarge`338px`,
  ].join(', ');
  return (
    <ProductPlugStyled>
      <Inner href={url}>
        <ProductPlugImage>
          {image?.responsiveImage?.src && (
            <Image
              src={image.responsiveImage.src}
              alt={image.alt || title}
              sizes={sizes}
              fill
            />
          )}
        </ProductPlugImage>
        <TextWrapper>
          <ProductPlugTitle $hasImage={!!image?.responsiveImage}>
            {title}
          </ProductPlugTitle>
        </TextWrapper>
      </Inner>
    </ProductPlugStyled>
  );
};
